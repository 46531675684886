export default {
  CANDIDATE: 'candidate',
  CONTRACT: 'contract',
  PLACEMENT: 'placement',
  SHIFT: 'shift',
  TIME_REGISTRATION: 'time_registration',
  DECLARATION: 'declaration',
  INVOICE: 'invoice',
  COLLECTIVE_AGREEMENT: 'collective_agreement',
};
