<template>
  <KAutocomplete
    v-model="selected"
    v-bind="$attrs"
    :items="refersTo"
    v-on="$listeners"
  />
</template>

<script>
import referTo from '@/application/enums/refersTo.js';
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';

export default {
  name: 'ReferToAutocomplete',
  components: { KAutocomplete },
  props: {
    value: {
      type: [Array, String],
    },
  },
  data: () => ({ selected: [] }),
  computed: {
    refersTo() {
      return Object.values(referTo).map(referTo => ({
        text: this.$t(`task.type.${referTo}`),
        value: referTo,
      }));
    },
  },
  created() {
    if (this.value) this.selected = Array.isArray(this.value) ? this.value : [this.value];
  },
};
</script>
