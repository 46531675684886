<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      {{ $tc('task.title', 2) }}
    </template>
    <template #filters="{ attrs, on }">
      <TaskFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <KCrudTable
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :params="filters"
        :search="searchQuery"
        language-prefix="task.fields"
        resource="role"
        @click:row="goToTask($event)"
      >
        <template #item.name="{ item }">
          <div>{{ item.name }}</div>
          <div class="text-body-2">
            {{ item.subtitle }}
          </div>
        </template>
        <template #item.priorityId="{ item }">
          <TaskPriorityIcon
            v-if="item.priorityId"
            :priority="item.priorityId"
          />
        </template>
        <template #item.assigned="{ item }">
          <div v-if="item.assignedUsers">
            {{ item.assignedUsers.map(user => user.fullName).join(', ') }}
          </div>
          <div v-else-if="item.assignedRoles">
            {{ item.assignedRoles.join(', ') }}
          </div>
          <div
            v-else
            class="text--disabled"
          >
            {{ $t('task.notAssigned') }}
          </div>
        </template>
        <template #item.pickedUpBy="{ item }">
          <div v-if="item.pickedUpBy">
            {{ item.pickedUpBy.fullName }}
          </div>
          <div
            v-else
            class="text--disabled"
          >
            {{ $t('task.notPickedUp') }}
          </div>
        </template>
        <template #actions="{ item }">
          <v-btn
            v-if="!item.isCompleted && (!item.pickedUpBy || item.pickedUpBy.id !== userId)"
            color="primary"
            depressed
            small
            tile
            @click.stop="handlePickUp(item)"
          >
            {{ $t('task.actions.pickUp') }}
          </v-btn>
          <v-btn
            v-if="item.comment"
            icon
            @click.stop="openTaskCommentDialog(item)"
          >
            <v-icon>$internalNote</v-icon>
          </v-btn>
        </template>
      </KCrudTable>
      <TaskCommentDialog
        v-model="isTaskCommentDialogOpen"
        :task="taskCommentDialogValues"
      />
    </template>
  </KCrudLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import TaskPriorityIcon from '@/modules/task/components/TaskPriorityIcon.vue';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { index } from '@/modules/task/api/task.js';
import taskToRoute from '@/modules/task/util/taskToRoute.js';
import TaskFilter from '@/modules/task/components/TaskFilter.vue';
import Task from '@/modules/task/mixins/Task.vue';
import TaskCommentDialog from '@/modules/task/components/TaskCommentDialog.vue';

export default {
  name: 'TaskOverview',
  components: {
    TaskFilter,
    TaskPriorityIcon,
    KCrudTable,
    KCrudLayout,
    TaskCommentDialog,
  },
  mixins: [Task, querySyncMixin],
  data: () => ({
    searchQuery: '',
    filters: {},
    isTaskCommentDialogOpen: false,
    taskCommentDialogValues: null,
  }),
  computed: {
    crudHeaders() {
      return [
        {
          value: 'priorityId',
          width: 120,
          align: 'center',
        },
        { value: 'name' },
        {
          value: 'assigned',
          sortable: false,
        }, // this is a fake value not returned by the api and therefore not sortable
        { value: 'pickedUpBy' },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [{
      exact: true,
      to: { name: 'task' },
      text: this.$tc('task.title', 2),
    }]);
  },
  methods: {
    indexRequest() {
      return index(...arguments);
    },
    handlePickUp(task) {
      this.pickUpTask(task, this.$refs.table.reloadPaginator);
    },
    goToTask(task) {
      const route = taskToRoute(task.refersTo, task.entities);
      if (!route) return;
      this.$router.push(route);
    },
    openTaskCommentDialog(task) {
      this.taskCommentDialogValues = task;
      this.isTaskCommentDialogOpen = true;
    },
    closeTaskCommentDialog() {
      this.taskCommentDialogValues = null;
      this.isTaskCommentDialogOpen = false;
    },
  },
};
</script>
