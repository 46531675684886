<template>
  <k-field-group language-prefix="task.fields">
    <k-sidebar
      v-bind="$attrs"
      :save-button-text="$t('shift.actions.applyFilter')"
      :title="$t('task.filterTitle')"
      fixed
      right
      temporary
      width="300"
      v-on="$listeners"
      @click:confirm="handleFilterClick"
    >
      <h5 class="mt-4 primary--text">
        {{ $t('task.fields.show') }}
      </h5>
      <KCheckbox
        v-model="showCompleted"
        field="completed"
        dense
        hide-details
      />
      <KCheckbox
        v-model="showUncompleted"
        field="uncompleted"
        dense
        hide-details
      />
      <h5 class="mt-4 primary--text">
        {{ $t('task.fields.assigned') }}
      </h5>
      <KCheckbox
        v-model="form.assignedToMe"
        field="assignedToMe"
        dense
        hide-details
      />
      <KCheckbox
        v-model="form.pickedUpByMe"
        field="pickedUpByMe"
        dense
        hide-details
      />
      <KCheckbox
        v-model="form.notAssigned"
        field="notAssigned"
        dense
        hide-details
        class="mb-3"
      />
      <PriorityAutocomplete
        v-model="form.priorities"
        class="mt-4"
        field="priorityId"
        multiple
        :return-object="false"
      />
      <ReferToAutocomplete
        v-model="form.refersTo"
        field="referToId"
        multiple
        :return-object="false"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar';
import PriorityAutocomplete from '@/modules/task/components/PriorityAutocomplete.vue';
import ReferToAutocomplete from '@/modules/task/components/ReferToAutocomplete.vue';

export default {
  name: 'TaskFilter',
  components: {
    KSidebar,
    ReferToAutocomplete,
    PriorityAutocomplete,
    KCheckbox,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    showCompleted: false,
    showUncompleted: false,
    form: {
      assignedToMe: false,
      pickedUpByMe: false,
      notAssigned: false,
      priorities: [],
      refersTo: [],
    },
  }),
  watch: {
    filters: {
      handler(values) {
        this.showCompleted = values.isCompleted === null || values.isCompleted === true;
        this.showUncompleted = values.isCompleted === null || values.isCompleted === false;
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      let isCompleted = null;

      if (this.showCompleted && !this.showUncompleted) {
        isCompleted = true;
      }

      if (!this.showCompleted && this.showUncompleted) {
        isCompleted = false;
      }

      this.$emit('update:filters', {
        ...this.form,
        isCompleted,
      });
      this.$emit('input', false);
    },
  },
};
</script>
