<template>
  <v-dialog
    v-if="task"
    v-bind="$attrs"
    max-width="600"
    v-on="$listeners"
  >
    <v-sheet class="pa-6">
      <v-row>
        <v-col>
          <h4 class="primary--text">
            {{ task.name }}
          </h4>
        </v-col>
        <v-btn
          color="grey lighten-2"
          fab
          x-small
          dark
          :elevation="0"
          @click="closeDialog"
        >
          <v-icon color="black">
            $close
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <table class="task-info">
            <tr
              v-for="(detail, key) in taskInfo"
              :key="key"
            >
              <td
                valign="top"
                class="grey--text text--darken-2 font-weight-bold pr-3 text-no-wrap"
              >
                {{ $t(`task.taskCommentDialog.${key}`) }}
              </td>
              <td> {{ detail }} </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import dayjs from '@/plugins/dayjs';

export default {
  name: 'TaskCommentDialog',
  props: {
    task: {
      type: Object,
      required: false,
    },
  },
  computed: {
    taskInfo() {
      return {
        completedBy: this.task.pickedUpBy.fullName,
        completedAt: dayjs(this.task.completedAt).format('LLL'),
        comment: this.task.comment,
      };
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
table.task-info {
  line-height: 2;
  font-size: 14px;
}
</style>
