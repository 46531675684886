<template>
  <k-autocomplete
    v-model="selected"
    v-bind="$attrs"
    :items="priorities"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <TaskPriorityIcon
        :priority="item.value"
        class="mr-3"
      />
      {{ item.text }}
    </template>
    <template #selection="{ item }">
      <div class="mr-3 mb-2">
        <TaskPriorityIcon
          :priority="item.value"
          class="mr-3"
        />
        {{ item.text }}
      </div>
    </template>
  </k-autocomplete>
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import priority from '@/application/enums/priority.js';
import TaskPriorityIcon from '@/modules/task/components/TaskPriorityIcon.vue';

export default {
  name: 'PriorityAutocomplete',
  components: {
    TaskPriorityIcon,
    KAutocomplete,
  },
  props: {
    value: {
      type: [Array, Number],
    },
  },
  data: () => ({ selected: [] }),
  computed: {
    priorities() {
      return Object.values(priority).map(priority => ({
        text: this.$t(`task.priorities.${priority}`),
        value: priority,
      }));
    },
  },
  created() {
    if (this.value) this.selected = Array.isArray(this.value) ? [this.value] : this.value;
  },
};
</script>
